/* Footer.css */
.footer {
  background-color: #f8f9fa;
  border-top: 1px solid #e7e7e7;
}

.footer .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer .text-muted {
  margin: 0;
}

.footer .float-end {
  margin: 0;
}
