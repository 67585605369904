/* Navbar.css */
.navbar-custom {
  background-color: #00050a; /* Light grey background */
}

.nav-link.active {
  font-weight: bold;
  color: #007bff; /* Adjust the color to your preference */
}

/* Add this to your CSS file */
.nav-link.active {
  font-weight: bold;
  color: #007bff; /* Adjust the color to your preference */
}
